import { useEffect, useMemo, useState } from "react";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import {
  Column,
  useTable,
  useSortBy,
  ColumnInstance,
  Row,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
} from "@fortawesome/pro-light-svg-icons";
import { faEnvelope, faCommentAltDots } from "@fortawesome/pro-regular-svg-icons";
import { faDoNotEnter } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import MainContainer from "../../UI/MainContainer";
import MainFooter from "../../UI/MainFooter";
import NoResultMessage from "../../UI/NoResultMessage";
import saveAs from "file-saver";
import { getDate } from "../../../utils/getDate";
import Pagination from "../../UI/Pagination";
import { ButtonTypes, SnackBarType, StatusBadgeTypes } from "../../../utils/Constants";
import StatusBadge from "../../UI/StatusBadge";
import SearchSection from "../../UI/SearchSection";
import FilterSection from "./SubscribersFilterSection";
import Button from "../../UI/Button";
import { FilterState } from './SubscribersFilterSection';
import SubscribersApi from "../../../store/api/SubscribersApi";
import Subscriber from "../../../types/Subscriber";
import LastConfirmWarningModal from "../../UI/LastConfirmWarningModal";
import LoadingSpinner from "../../UI/LoadingSpinner";

export type SortConfig = {
  column: string;
  sortType: "Ascending" | "Descending";
};

export type SnackbarType = {
  message: string;
  type: number;
  show: boolean;
};

const Subscribers: React.FC = () => {
  const { t } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [responseData, setResponseData] = useState<Subscriber[]>([]);
  const [filterState, setFilterState] = useState<FilterState>({
    status: "AL",
    dateFrom: null,
    dateTo: null,
  });
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    message: "",
    type: 0,
    show: false,
  });
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dataCount, setDataCount] = useState<number>(0);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(undefined);
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [unsubscribeId, setUnsubscribeId] = useState<number | null>(null);
  const {
    getSubscribers,
    exportSubscribers,
    updateStatus,
  } = SubscribersApi();

  const getSubscribersList = () => {
    setShowLoading(true);
    getSubscribers(
      `${"subscribers/"}${constructQueryParams()}`,
      async (response: Response) => {
        const result = await response.json();
        if (!response.ok) {
          return;
        }
        setResponseData(result.subscribers)
        setDataCount(result.total_count)
        setShowLoading(false);
      }
    );
  };

  const constructQueryParams = (includePagination: boolean = true) => {
    let queryParams: string[] = [];
    if (includePagination) queryParams.push(`page_number=${pageIndex + 1}&page_size=${pageSize}`);
    if (searchInput) {
      queryParams.push(`search_subscriber=${searchInput}`);
    }
    if (filterState.dateFrom !== null) {
      queryParams.push(`subscriber_from=${getDate(filterState.dateFrom.toString(), false)}`);
    }
    if (filterState.dateTo !== null) {
      queryParams.push(`subscriber_to=${getDate(filterState.dateTo.toString(), false)}`);
    }
    if (filterState.status !== "AL") {
      queryParams.push(`subscriber_status=${filterState.status}`);
    }
    if (sortConfig) {
      queryParams.push(`sort_by=${sortConfig.column}&sort_type=${sortConfig.sortType}`);
    }
    return `?${queryParams.join("&")}`;
  };

  useEffect(() => {
    setPageIndex(0);
  }, [searchInput, filterState]);

  useEffect(() => {
    getSubscribersList();
  }, [pageIndex, pageSize, searchInput, filterState, sortConfig]);

  const toggleSelectAll = () => {
    const responseIds = responseData.map((row) => row.id);
    if (responseIds.every((id) => selectedRowIds.includes(id))) {
      const newSelectedIds = selectedRowIds.filter((id) => !responseIds.includes(id));
      setSelectedRowIds(newSelectedIds);
    } else {
      const newSelectedIds = [
        ...selectedRowIds,
        ...responseData
          .filter((row) => !selectedRowIds.includes(row.id))
          .map((row) => row.id),
      ];
      setSelectedRowIds(newSelectedIds);
    }
  };

  const toggleRowSelection = (rowId: number) => {
    setSelectedRowIds((prevSelectedRowIds) =>
      prevSelectedRowIds.includes(rowId)
        ? prevSelectedRowIds.filter((id) => id !== rowId)
        : [...prevSelectedRowIds, rowId]
    );
  };

  const renderSortableHeader = (column: ColumnInstance<Subscriber>, label: string) => {
    const isSorted = sortConfig?.column === column.id;
    const isSortedDesc = isSorted && sortConfig?.sortType === "Descending";
    const isSortedAsc = isSorted && sortConfig?.sortType === "Ascending";

    const handleSort = () => {
      if (isSorted) {
        if (isSortedDesc) {
          setSortConfig(undefined);
        } else {
          setSortConfig({
            column: column.id,
            sortType: "Descending",
          });
        }
      } else {
        setSortConfig({
          column: column.id,
          sortType: "Ascending",
        });
      }
    };

    return (
      <div className="flex items-center cursor-pointer" onClick={handleSort}>
        {label}
        <div className="flex flex-col ml-4">
          <FontAwesomeIcon
            icon={faAngleUp}
            className={isSortedAsc ? "text-black41" : "text-gray-df"}
          />
          <FontAwesomeIcon
            icon={faAngleDown}
            className={isSortedDesc ? "text-black41" : "text-gray-df"}
          />
        </div>
      </div>
    );
  };

  const columns: Column<Subscriber>[] = useMemo(
    () => [
      {
        Header: (props) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              onChange={toggleSelectAll}
              checked={responseData.length > 0 && responseData.every((row) => selectedRowIds.includes(row.id))}
              className="table__checkbox mr-4"
            />
            {renderSortableHeader(props.column, t("email"))}
          </div>
        ),
        accessor: "email",
        Cell: ({ row }: { row: Row<Subscriber> }) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={selectedRowIds.includes(row.original.id)}
              onChange={() => toggleRowSelection(row.original.id)}
              className="table__checkbox mr-4"
            />
            <span>{row.original.email}</span>
          </div>
        ),
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("first_name")),
        accessor: "first_name",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("last_name")),
        accessor: "last_name",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("subscription_date")),
        accessor: "subscription_date",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("status")),
        accessor: "status",
        Cell: ({ value }: { value: string }) => {
          let statusType: StatusBadgeTypes | undefined;
          switch (value) {
            case "SB":
              statusType = StatusBadgeTypes.SUBSCRIBED;
              break;
            case "US":
              statusType = StatusBadgeTypes.UNSUBSCRIBED;
              break;
            case "PD":
              statusType = StatusBadgeTypes.PENDING;
              break;
            default:
              statusType = undefined;
          }
          return statusType ? <StatusBadge type={statusType} /> : <span>{t("unknown_status")}</span>;
        },
      },
    ],
    [t, selectedRowIds, responseData, sortConfig]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<Subscriber>({
    columns: columns,
    data: responseData,
  }, useSortBy);

  const unsubscribeHandler = async (_id: number) => {
    setShowUnsubscribeModal(false)
    setShowLoading(true);
    updateStatus(async (response: Response) => {
      if (response.ok) {
        const result = await response.json();
        const newData = [...responseData];
        const index = newData.findIndex((data) => data.id === _id);

        newData[index] = {
          ...newData[index],
          email: result.email,
          first_name: result.first_name,
          last_name: result.last_name,
          status: result.status,
          subscription_date: result.subscription_date,
          unsubscription_date: result.unsubscription_date,
        };

        setResponseData(newData);
        setSnackbar({
          message: t("unsubscribed_successfully"),
          type: SnackBarType.DELETE,
          show: true,
        });
      }
      setShowLoading(false);
    }, _id);
  };

  const exportHandler = () => {
    setShowLoadingSpinner(true);
    exportSubscribers(
      constructQueryParams(false) +
      (selectedRowIds.length > 0
        ? `&selected_subscribers=${selectedRowIds
          .map((selectedItem) => selectedItem)
          .join(",")}`
        : ""),
      async (response: Response) => {
        if (response.ok) {
          const blob = await response.blob();
          saveAs(blob, `"subscribers".csv`);
          setSnackbar({
            message: t("export_completely_message"),
            type: SnackBarType.DELETE,
            show: true,
          });
          setShowLoadingSpinner(false);
        } else {
          setShowLoadingSpinner(false);
        }
      }
    );
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            message: "",
            type: 0,
            show: false,
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>

      {showLoadingSpinner && <LoadingSpinner titleKey={t("export_to_csv")} onBackdrop={() => setShowLoadingSpinner(false)} />}

      <main className="alignment mt-4">
        <header className="flex flex-row justify-between items-center flex-wrap gap-x-32 gap-y-2">
          <h3 className="text-lg font-semibold text-gray-27 my-auto">
            {t("newsletter_subscribers")}
          </h3>
          {responseData.length > 0 && !showLoading && (
            <Button onClick={exportHandler} type={ButtonTypes.PRIMARY} className="max-w-[155px]">{t("export_to_csv")}</Button>
          )}
        </header>

        <div className="bg-white rounded shadow px-6 mt-4">
          <SearchSection
            onSearch={(_searchInput) => setSearchInput(_searchInput)}
            onSliderClicked={() => {
              setShowFilterSection((preState) => !preState)
            }}
            isFilterMode={showFilterSection}
          />
          {showFilterSection && (
            <FilterSection
              onApply={(appliedFilterState: FilterState) => {
                setFilterState(appliedFilterState);
              }}
              onReset={() => {
                setFilterState({
                  status: "AL",
                  dateFrom: null,
                  dateTo: null,
                })
              }}
              initialFilterState={filterState}
            />
          )}
        </div>

        <div className="relative bg-white rounded shadow px-4 mt-[6px] pb-6">
          <div className="mb-3 overflow-x-auto z-10 min-h-[400px]">
            {showLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} />
              </div>
            ) : responseData.length > 0 ? (
              <>
                <table {...getTableProps()} className="w-full text-black41 text-sm">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <th {...column.getHeaderProps()} className={`table__cell text-left sticky ${index === 0 && `z-10 left-0`
                            }`}>
                            {column.render("Header")}
                          </th>
                        ))}
                        <th className="table__cell"></th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="border-t border-gray-df"
                        >
                          {row.cells.map((cell, index) => (
                            <td
                              {...cell.getCellProps()}
                              className={`py-4 whitespace-nowrap table__cell ${index === 0 && `sticky left-0`
                                }`}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                          <td className="relative table__cell">
                            <div
                              onMouseOver={() => setHoveredRowId(row.original.id)}
                              onMouseOut={() => setHoveredRowId(null)}
                              className={`w-4 mx-2 relative ${row.original.status === "SB" && "hover:cursor-pointer"}`}
                              onClick={() => {
                                if (row.original.status === "SB") {
                                  setUnsubscribeId(row.original.id);
                                  setShowUnsubscribeModal(true);
                                }
                              }}
                            >
                              {row.original.status !== "PD" && (
                                <FontAwesomeIcon icon={row.original.status === "SB" ? faEnvelope : faCommentAltDots} className="text-base text-[#414141]" />
                              )}
                              {row.original.status === "SB" && (
                                <FontAwesomeIcon
                                  icon={faDoNotEnter}
                                  className="text-[10px] text-[#D11C1C] absolute bottom-0 right-0 -translate-y-[3px] translate-x-[1px]"
                                />
                              )}
                              {row.original.status === "US" && hoveredRowId === row.original.id && (
                                <div className="absolute -top-8 -right-6 p-1 rounded bg-white shadow">
                                  {t("unsubscription_date") + ": " + String(row.original.unsubscription_date)}
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <NoResultMessage noResultOrNoData={true} />
            )}
          </div>

          {!showLoading && responseData.length > 0 &&
            <Pagination
              dropdownKeys={["5", "10", "20"]}
              pageIndex={pageIndex}
              pageSize={pageSize}
              dataCount={dataCount}
              onPageChange={setPageIndex}
              onPageSizeChange={(newSize) => {
                setPageSize(newSize);
                setPageIndex(0);
              }}
            />
          }
        </div>

        {showUnsubscribeModal && (
          <LastConfirmWarningModal
            title="unsubscription"
            message="unsubscription_message"
            confirmButtonLabel="yes_unsubscribe"
            onCancel={() => setShowUnsubscribeModal(false)}
            onConfirm={() => {
              if (unsubscribeId !== null)
                unsubscribeHandler(unsubscribeId);
            }}
            onBackdrop={() => setShowUnsubscribeModal(false)}
          />
        )}
        <MainFooter />
      </main>
    </MainContainer>
  );
};

export default Subscribers;
