import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../images/logo-v.svg";

const MobileNavigationModal: React.FC<{
  headerTabs: { route: string; title: string }[];
  sidebarClassName: string;
  backdropClassName: string;
  onBackdrop: () => void;
}> = ({ headerTabs, sidebarClassName, backdropClassName, onBackdrop }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`w-screen h-screen bg-gray-4147 fixed top-0 left-0 z-30 cursor-pointer md:hidden ${backdropClassName}`}
        onClick={() => onBackdrop()}
      />
      <aside
        className={`w-64 h-screen bg-blue-primary fixed top-0 left-0 duration-500 z-40 ${sidebarClassName}`}
      >
        <div className="bg-white flex justify-center py-14">
          <img src={Logo} alt="CIMA" />
        </div>
        <nav className="flex flex-col mt-3">
          {headerTabs.map(({ route, title }, index) => (
            <NavLink
              key={index}
              className="text-white px-4 py-2 my-2 hover:bg-white51"
              to={route}
              onClick={onBackdrop}
            >
              {t(title)}
            </NavLink>
          ))}
        </nav>
      </aside>
    </>
  );
};

export default MobileNavigationModal;
