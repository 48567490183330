import React from "react";
import backgroundImage from "../../images/background.png";
import logoImage from "../../images/logo-v.svg";

// const AuthContainer: React.FC = (props) => {
const AuthContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <main className="page">
      <img
        className="w-auto h-full absolute right-0 bottom-0"
        src={backgroundImage}
        alt=""
      />
      <section className="w-95/100 max-w-[490px] flex flex-col bg-white rounded p-8 z-10">
        <img className="w-40 mx-auto" src={logoImage} alt="CIMA" />
        {/* {props.children} */}
        {children}
      </section>
    </main>
  );
};

export default AuthContainer;
