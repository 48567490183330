import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons";

interface InformationSectionProps {
  className?: string;
  title: string;
  information: { label: string; value: string }[];
}

const InformationSection: React.FC<InformationSectionProps> = ({
  className = "",
  title,
  information,
}) => {
  const [showInfo, setShowInfo] = useState(true);

  return (
    <section className={`bg-white rounded ${className}`}>
      <div
        className={`px-6 py-3.5 flex justify-between items-center rounded-t bg-gray-612d ${!showInfo ? "rounded-b" : ""
          }`}
      >
        <p className={showInfo ? "text-sm text-black41" : "text-[15px] text-blue-secondary font-semibold"}>
          {title}
        </p>
        <FontAwesomeIcon
          icon={faAngleUp}
          className={`text-sm text-gray-8d cursor-pointer duration-300 transform ${showInfo ? "rotate-0" : "rotate-180"
            }`}
          onClick={() => setShowInfo((prev) => !prev)}
        />
      </div>
      <div
        className={`overflow-hidden transition-max-height duration-300 ${showInfo ? "max-h-screen" : "max-h-0"}`}
      >
        <div className="grid 888:grid-cols-3 640:grid-cols-2 grid-cols-1 gap-10 p-6">
          {information.map((info, index) => (
            <article key={index} className="text-sm text-black41">
              <p className="font-semibold">{info.label}</p>
              <p>{info.value}</p>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InformationSection;
