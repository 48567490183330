import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometer } from "@fortawesome/pro-solid-svg-icons";

interface BreadCrumbProps {
  links: { title: string; path?: string }[];
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ links }) => {
  return (
    <header className="flex items-center mx-4">
      <Link className="text-gray-8d" to="/training-candidates">
        <FontAwesomeIcon icon={faTachometer} />
      </Link>
      <ul className="flex">
        {links.map(({ title, path }, index) => (
          <li key={index} className="flex items-center text-[13px] text-black41">
            <div className="w-1 h-1 mx-2 rounded-full bg-gray-27" />
            {path ? (
              <Link to={path}>{title}</Link>
            ) : (
              <span>{title}</span>
            )}
          </li>
        ))}
      </ul>
    </header>
  );
};

export default BreadCrumb;
