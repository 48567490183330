import useAPI from "../../hooks/useAPI";

const TrainingCandidatesApi = () => {
  const { sendRequest } = useAPI();

  const getTrainingCandidates = (url: string, trainingCandidateProcessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: url,
      },
      trainingCandidateProcessResponse
    );
  };

  const deleteTrainingCandidate = (trainingCandidateProcessResponse: (response: Response) => void, _id: number) => {
    sendRequest(
      {
        url: `training-candidate/${_id}/`,
        method: "DELETE",
      },
      trainingCandidateProcessResponse
    );
  };

  const exportTrainingCandidates = (queryParams: string, exportPreccessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `training-candidate/export/${queryParams}`,
        method: "GET",
      },
      exportPreccessResponse
    );
  };

  const getTrainingCandidateDetails = (id: string,trainingCandidateProcessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `training-candidate/${id}/`,
      },
      trainingCandidateProcessResponse
    );
  };

  const updateStatus = (
    trainingCandidateProcessResponse: (response: Response) => void,
    _id: number,
    _status: string
  ) => {
    sendRequest(
      {
        url: `training-candidate/${_id}/`,
        method: "PATCH",
        body: JSON.stringify({
          status: _status,
        }),
      },
      trainingCandidateProcessResponse
    );
  };

  const getCountryList = (getListProcessResponse: (items: any) => void) => {
    sendRequest(
      {
        url: "country-list/",
      },
      getListProcessResponse
    );
  };

  return { getTrainingCandidates, deleteTrainingCandidate, exportTrainingCandidates, getTrainingCandidateDetails, updateStatus, getCountryList };
};

export default TrainingCandidatesApi;
