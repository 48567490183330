import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown, { DropdownType } from "../../UI/Dropdown";
import DatePicker from "../../UI/DatePicker";
import Button from "../../UI/Button";
import FilterTag from "../../UI/FilterTag";
import { ButtonTypes } from "../../../utils/Constants";

export interface FilterState {
  referral: string;
  status: string;
  dateFrom: Date | null;
  dateTo: Date | null;
}

interface FilterSectionProps {
  referralList: [string, string][];
  onApply: (filterState: FilterState) => void;
  onReset: () => void;
  initialFilterState: FilterState;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  referralList,
  onApply,
  onReset,
  initialFilterState,
}) => {
  const { t } = useTranslation();
  const [localFilterState, setLocalFilterState] = useState<FilterState>(initialFilterState);
  const [appliedFilterState, setAppliedFilterState] = useState<FilterState>(initialFilterState);

  const isAnyFilterApplied = appliedFilterState.referral !== "AL" ||
    appliedFilterState.status !== "AL" ||
    appliedFilterState.dateFrom !== null ||
    appliedFilterState.dateTo !== null;

  useEffect(() => {
    setLocalFilterState(initialFilterState);
    setAppliedFilterState(initialFilterState);
  }, [initialFilterState]);

  const updateFilters = (newState: FilterState, apply: boolean = true) => {
    setAppliedFilterState(newState);
    setLocalFilterState(newState);
    if (apply) {
      onApply(newState);
    } else {
      onReset();
    }
  };

  const handleApply = () => {
    updateFilters(localFilterState);
  };

  const handleReset = () => {
    updateFilters(initialFilterState, false);
  };

  const handleRemoveFilter = (filterType: keyof FilterState) => {
    const updatedFilterState = {
      ...appliedFilterState,
      [filterType]: filterType === "dateFrom" || filterType === "dateTo" ? null : "AL",
    };

    if (filterType === "dateFrom" || filterType === "dateTo") {
      updatedFilterState.dateFrom = null;
      updatedFilterState.dateTo = null;
    }

    updateFilters(updatedFilterState);
  };

  return (
    <div className="flex flex-col bg-white py-4">
      <div className="flex flex-wrap justify-between items-end gap-x-6">
        <div className="flex flex-wrap gap-x-6 gap-y-1">
          <div>
            <label className="text-sm text-black41">{t("referral_source")}</label>
            <Dropdown
              type={DropdownType.PRIMARY}
              keys={["AL", ...referralList.map(ref => ref[0])]}
              selectedKey={localFilterState.referral}
              onOptionSelected={(option) => setLocalFilterState({ ...localFilterState, referral: option })}
              getValueByKey={(key) => {
                if (key === "AL") return t("al");
                const foundReferral = referralList.find((referral) => referral[0] === key);
                return foundReferral ? foundReferral[1] : "";
              }}
            />
          </div>

          <div>
            <label className="text-sm text-black41">{t("contact_date")}</label>
            <div className="flex gap-x-3" dir="ltr">
              <DatePicker
                inputClassName="w-[124px]"
                containerClassName="w-[124px]"
                selected={localFilterState.dateFrom}
                placeholderText={t("from")}
                onChange={(date) => setLocalFilterState({ ...localFilterState, dateFrom: date })}
                maxDate={localFilterState.dateTo}
              />
              <DatePicker
                inputClassName="w-[124px]"
                containerClassName="w-[124px]"
                selected={localFilterState.dateTo}
                placeholderText={t("to")}
                onChange={(date) => setLocalFilterState({ ...localFilterState, dateTo: date })}
                minDate={localFilterState.dateFrom}
              />
            </div>
          </div>

          <div>
            <label className="text-sm text-black41">{t("status")}</label>
            <Dropdown
              type={DropdownType.PRIMARY}
              keys={["AL", "PD", "RS"]}
              selectedKey={localFilterState.status}
              onOptionSelected={(option) => setLocalFilterState({ ...localFilterState, status: option })}
            />
          </div>
        </div>

        <div className="flex flex-grow justify-end mt-5 gap-x-6">
          {isAnyFilterApplied && (
            <Button className="text-blue-primary" onClick={handleReset} type={ButtonTypes.RESET}>
              {t("reset")}
            </Button>
          )}
          <Button onClick={handleApply} type={ButtonTypes.PRIMARY}>{t("apply")}</Button>
        </div>
      </div>

      {isAnyFilterApplied && (
        <div className="flex flex-wrap gap-3 mt-9">
          <label className="text-sm mt-2.5">
            {t("applied_filters")}:
          </label>
          {appliedFilterState.referral !== "AL" && (
            <FilterTag
              label={t("referral_source")}
              value={referralList.find(([key]) => key === appliedFilterState.referral)?.[1] || ""}
              onClose={() => handleRemoveFilter("referral")}
            />
          )}
          {appliedFilterState.dateFrom && appliedFilterState.dateTo && (
            <FilterTag
              label={t("contact_date")}
              value={`${appliedFilterState.dateFrom.toLocaleDateString()} - ${appliedFilterState.dateTo.toLocaleDateString()}`}
              onClose={() => handleRemoveFilter("dateFrom")}
            />
          )}
          {appliedFilterState.status !== "AL" && (
            <FilterTag
              label={t("status")}
              value={appliedFilterState.status === "PD" ? t("pd") : t("rs")}
              onClose={() => handleRemoveFilter("status")}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FilterSection;
